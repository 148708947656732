<template>
  <div>
    <iframe name="child" id="child" class="link" :src="linkObj.linkUrl" frameborder="0" v-if="isVisit"></iframe>
    <h1 v-else class="hint">{{ hintText }}</h1>
    <div class="goLogin" v-if="isShowGoBtn">
      <a-button type="primary" @click="goLogin">点击前往</a-button>
    </div>
  </div>
</template>

<script>
import Base64 from "@/utils/base64";
import { getLocalStorage } from "@/utils/storage";
import { userCheck } from "@/api/userAuth";
import { REQUEST_CODE } from "@/constant/status";
import { getDashboardReport, getKanbanReport } from "@/api/analysis";

const hintText = '暂无权限, 请前往i友工作台查看'

export default {
  name: "Templates",
  watch: {
    openUrl(newValue) {
      this.linkObj = Base64.linkDecode(newValue);
      if (this.linkObj.linkType === '99') {
        this.isVisit = true;
      } else {
        if (getLocalStorage("user").commonToken) {
          this.checkUser();
        } else {
          this.hintText = hintText;
          this.isShowGoBtn = true;
        }
      }
    },
  },
  data() {
    return {
      // 链接加密地址
      openUrl: "",
      // 链接数据
      linkObj: {},
      // 是否允许访问外部链接
      isVisit: false,
      // 无权限提示语
      hintText: "",
      // 是否显示前往按钮
      isShowGoBtn: false,
    };
  },
  methods: {
    /** 网络请求 */
    // 校验用户是否是i友工作台用户
    async checkUser() {
      try {
        const result = await userCheck();
        if (result.code === REQUEST_CODE && Object.keys(result.data).length) {
          this.getCurrentVisitReport();
        } else {
          this.hintText = hintText;
          this.isShowGoBtn = true;
        }
      } catch (error) {
        this.hintText = hintText;
        this.isShowGoBtn = true;
      }
    },
    // 获取当前用户可访问报表
    async getCurrentVisitReport() {
      try {
        if (this.linkObj.linkType === "0") {
          const result = await getDashboardReport();
          if (result.code === REQUEST_CODE) {
            this.checkUserVisitCurrentLink(result.data);
          } else {
            this.hintText = hintText;
            this.isShowGoBtn = true;
          }
        } else if (this.linkObj.linkType === "1") {
          const result = await getKanbanReport();
          if (result.code === REQUEST_CODE) {
            this.checkUserVisitCurrentLink(result.data);
          } else {
            this.hintText = hintText;
            this.isShowGoBtn = true;
          }
        } else {
          this.hintText = hintText;
          this.isShowGoBtn = true;
        }
      } catch (error) {
        this.hintText = hintText;
        this.isShowGoBtn = true;
      }
    },

    // 校验用户是否允许访问当前链接
    async checkUserVisitCurrentLink(linkAggregation) {
      if (linkAggregation.total !== 0) {
        const linkList = linkAggregation.list;
        for (let index = 0; index < linkList.length; index++) {
          if (linkList[index].id === this.linkObj.linkId) {
            this.isVisit = true;
            break;
          }
        }
        if (!this.isVisit) {
          this.hintText = hintText;
          this.isShowGoBtn = true;
        }
      } else {
        this.hintText = hintText;
        this.isShowGoBtn = true;
      }
    },

    // 前往i友工作台
    goLogin() {
      document.title = "i友工作台";
      this.$router.push({ path: "/home" });
    },

    // 校验报告类型
    checkReportType() {
      return this.$route.query.reportType === 99 || this.$route.query.reportType === '99';
    }
  },
  created() {
    document.title = this.$route.query.reportName;
    if (this.$route.query.openUrl && getLocalStorage("user").commonToken) {
      this.openUrl = this.$route.query.openUrl;
    } else if (this.$route.query.openUrl) {
      this.openUrl = this.$route.query.openUrl;
    } else {
      this.hintText = hintText;
      this.isShowGoBtn = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.link {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
}
.hint {
  text-align: center;
  padding-top: 50px;
}
.goLogin {
  text-align: center;
}
</style>

