import { fetchApi } from "@/utils/axios";
import URL from "../urlConfig";

// 获取dashboard报表
export const getDashboardReport = () => {
  const submitData = {
    classifyIdList: [],
    lableIdList: [],
    page: 1,
    reportName: '',
    size: 999
  }
  return fetchApi(URL.GET_DASHBOARD_REPORT, submitData, "POST", "body");
};

// 获取看板报告
export const getKanbanReport = () => {
  const submitData = {
    classifyIdList: [],
    lableIdList: [],
    page: 1,
    reportName: '',
    size: 999
  }
  return fetchApi(URL.GET_KANBAN_REPORT, submitData, "POST", "body");
}
